





















import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop, Watch } from 'vue-property-decorator';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import { mixins } from 'vue-class-component';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import GUUID from '@/utils/GUUID';
import MessageGroup from '@/models/graphql/MessageGroup';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';
import Session from '@/models/graphql/Session';
import { Data } from '@/utils/types/WidgetData';
import { Getter } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component({
  components: { MessageBoxMessages },
})
export default class ChatComponent
  extends mixins(VueBaseWidget, VueRegisterStoreWidget, ChatModuleHelper) {
  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  private tempGroupId = GUUID.uuidv4();

  private get isChatEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled;
  }

  private get session(): Session | null {
    if (this.data && this.data.length > 0) {
      return Session.hydrate(this.data[0]);
    }
    return null;
  }

  beforeCreate(): void {
    this.storeContext = 'ChatWidgetStore';
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'session',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: {
              uid: this.payload.entityCode,
            },
          },
        }),
        fragmentName: 'sessionChatFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  @Watch('session')
  loadMessageGroup(): void {
    if (this.session) {
      this.lookupForMessageGroupWithTarget(this.session.uid)
        .then((group) => {
          if (group) {
            this.setSelectedGroup(group.uid);
          } else {
            const speakers = (this.session?.speakerRoles ? this.session.speakerRoles : [])
              .map((sr) => sr.speaker.user);
            const tempMessageGroup = MessageGroup.hydrate({
              uid: this.tempGroupId,
              tempId: this.tempGroupId,
              groupType: GroupType.GROUP,
              users: [...speakers],
              target: { ...this.session, __typename: EntityType.SESSION },
              messages: [],
              updated: true,
              selected: true,
            });
            this.setNewConversation(tempMessageGroup);
            this.setSelectedGroup(tempMessageGroup.uid);
          }
        });
    }
  }

  private lookupForMessageGroupWithTarget(targetId: string): Promise<MessageGroup | undefined> {
    return this.$store.dispatch(`${this.storeContextPath}/lookupForMessageGroupWithTarget`, targetId);
  }

  private setNewConversation(group: MessageGroup): void {
    return this.$store.commit(`${this.storeContextPath}/setNewConversation`, group);
  }

  private setSelectedGroup(groupId: string): void {
    this.$store.commit(`${this.storeContextPath}/setSelectedGroup`, groupId);
  }
}
